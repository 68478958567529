import React, {useContext, useState, useEffect } from 'react';
//import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { GlobalContext } from '../../../GlobalContext';


function Gallery(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    const [Images,setImages] = useState([]);
    const [ShowImage,setShowImage] = useState(false);
    const [ImageIndex,setImageIndex] = useState(0);
    useEffect(()=>{
        setImages([
            {title:'',description:'',image:'/images/gallery/b1.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b2.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b3.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b4.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b5.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b6.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b7.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b8.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b9.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b10.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b11.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b12.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b13.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b14.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b15.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b16.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b17.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b18.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b19.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b20.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b21.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b22.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b23.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b24.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b25.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b26.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b27.jpg',tag:'Building'},
            {title:'',description:'',image:'/images/gallery/b28.jpg',tag:'Building'},

            {title:'',description:'',image:'/images/gallery/p1.jpg',tag:'Person'},
            {title:'',description:'',image:'/images/gallery/p2.jpg',tag:'Person'},
            {title:'',description:'',image:'/images/gallery/p3.jpg',tag:'Person'},
            {title:'',description:'',image:'/images/gallery/p4.jpg',tag:'Person'},
            {title:'',description:'',image:'/images/gallery/p5.jpg',tag:'Person'},
            {title:'',description:'',image:'/images/gallery/p6.jpg',tag:'Person'},
        ]);
    },[]);
    return (
        <div className="courses-area pb-70">
            <div className="container">
                <div className="section-title text-center mb-45">
                    <br/><br/><br/>
                </div>
                <div className="row">
                    {
                        Images.map((image,index)=>{
                            return (
                                <div key={image.image} className="col-lg-4 col-md-6" onClick={()=>{setShowImage(true);setTimeout(()=>{setImageIndex(index);},2000);}}>
                                    <div className="courses-item">
                                        <a href="javascript:;">
                                            <img src={image.image} alt={image.title} />
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>    
            {ShowImage && 
            <>
                <lightbox
                    mainSrc={Images[ImageIndex].image}
                    nextSrc={Images[(ImageIndex + 1) % Images.length].image}
                    prevSrc={Images[(ImageIndex + Images.length - 1) % Images.length].image}
                    onCloseRequest={() => setShowImage(false)}
                    onMovePrevRequest={() =>setImageIndex((ImageIndex + Images.length - 1) % Images.length)}
                    onMoveNextRequest={() =>setImageIndex((ImageIndex + 1) % Images.length)}
                />
            </>
            }
        </div>
    );
}
export default Gallery;
