import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function ExecutiveOfficerScreen() {
	const {skin,Endpoint} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Executive Officer :: A J Vidya Parishad</title></Helmet>
            <ScrollToTop />
			<Header />
			<HeroSection4 header={"Executive Officer's Desk!"} description={"Executive Officer :: A J Vidya Parishad"} />
			<div className="instructors-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="instructors-details-img">
                                <img src={"/images/team/executiveofficer.jpg"} alt="Chairman" />
                                <ul className="social-link">
                                    <li className="social-title">Follow me:</li>
                                    <li><a href="https://www.facebook.com/" target="_blank"><i className="ri-facebook-fill"></i></a></li>
                                    <li><a href="https://twitter.com/" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                                    <li><a href="https://www.pinterest.com/" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="instructors-details-content pl-20">
                                <h3>Sri G.V.D.N.Leela Kumar</h3>
                                <span className="sub-title">Assistant Commissioner & Executive Officer</span>
                                <ul>
                                    <li>Phone number: <span><a href="tel:+9108672229000 ">+91 08672-229000</a></span></li>
                                    <li>Email: <span><a href="mailto:ExecutiveOfficer@ajvidyaparishad.org">ExecutiveOfficer@ajvidyaparishad.org</a></span></li>
                                    <li>Address: <span>Raju Peta</span></li>
                                    <li>City/District: <span>Machilipatnam, Krishna District</span></li>
                                    <li>State/Pincode: <span>Andhra Pradesh - 521 001</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<BecomeSupportPartnerSection1 />
			<Footer />
		</>
	);
}
export default ExecutiveOfficerScreen;
