import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../../../GlobalContext';
function VisitorsSection1(props) {
    const theme = props.theme == undefined?"":props.theme;
    const bgcolor = props.bgcolor == undefined?"":props.bgcolor;
	const {skin} = useContext(GlobalContext);
    const [VisitorsList,setVisitorsList] = useState([]);
    useEffect(()=>{
        var vl = [
        {fullname:"P. Sivaswami Ayyer",visiteddate:"4th Dec, 1910"},
        {fullname:"L. D. Swami Kannu",visiteddate:"03rd July, 1911"},
        {fullname:"J. A. Atkinson",visiteddate:"18th Oct, 1911"},
        {fullname:"D. Sitarama Rao",visiteddate:"20th Nov, 1911"},
        {fullname:"G. Venkata Ramaiah",visiteddate:"01th June, 1912"},
        {fullname:"M.Ramachandra Rao",visiteddate:"24th August, 1912"},
        {fullname:"Mr.&Mrs.A.L Pathi",visiteddate:"15th Apr, 1914"},
        {fullname:"E. A. Rhenius",visiteddate:"27th Nov, 1914"},
        {fullname:"Pentland",visiteddate:"08th Dec, 1914"},
        {fullname:"M. Nanjunda Rao",visiteddate:"13th January, 1915"},
        {fullname:"C. Swaminathan",visiteddate:"25th August, 1915"},
        {fullname:"L. D. Swami Kannu ",visiteddate:"31th Aug, 1916"},
        {fullname:"Ch.L.Narasimham",visiteddate:"20th Nov, 1916"},
        {fullname:"J. R. Aria",visiteddate:"17th August, 1917"},
        {fullname:"B. Rama Rau",visiteddate:"16th Feb, 1919"},
        {fullname:"A. P. Patro",visiteddate:"27th Nov, 1924"},
        {fullname:"S Bharati",visiteddate:"22nd Sep, 1927"},
        {fullname:"S. Radhakrishnan",visiteddate:"15th Nov, 1928"},
        ];
        setVisitorsList(vl);
    },[]);
    return (
        <div className="instructors-area instructors-area-rs pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center mb-45">
                    <h2>Visitors</h2>
                </div>
                <div className="row justify-content-center">
                    {
                        VisitorsList.map((visitor,index)=>{
                            return (
                                <div key={index} className="col-lg-3 col-md-6">
                                    <div className="instructors-card">
                                        <a href="#">
                                            <img src={"/images/visitors/default.jpg"} alt={visitor.fullname} />
                                        </a>
                                        <div className="content">
                                            <ul className="instructors-social">
                                                <li className="share-btn"><i className="ri-add-line"></i></li>
                                                <li><a href="https://www.facebook.com/" target="_blank"><i className="ri-facebook-fill"></i></a></li>
                                                <li><a href="https://www.instagram.com/" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                                <li><a href="https://twitter.com/" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                                                <li><a href="https://www.linkedin.com/" target="_blank"><i className="ri-linkedin-box-line"></i></a></li>
                                            </ul>
                                            <b><a href="#">{visitor.fullname}</a></b>
                                            <span>{visitor.visiteddate}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}
export default VisitorsSection1;
