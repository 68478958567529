import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function ContactForm() {
	const {Endpoint} = useContext(GlobalContext);
    const [InProcess,setInProcess] = useState(false);
    const [FirstName,setFirstName] = useState("");
    const [LastName,setLastName] = useState("");
    const [Email,setEmail] = useState("");
    const [Mobile,setMobile] = useState("");
    const [Message,setMessage] = useState("");
    const [SuccessMessage,setSuccessMessage] = useState("");
    const [ErrorMessage,setErrorMessage] = useState("");
    const SendMessage = async ()=>{
            if(InProcess == true) return;
            setSuccessMessage("");
            setErrorMessage("");
            if(/^([a-zA-Z ]){2,30}$/.test(FirstName) === "") return setErrorMessage("Invalid First Name.");
            if(/^([a-zA-Z ]){2,30}$/.test(LastName) === "") return setErrorMessage("Invalid Last Name.");
            if(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(Email) === "") return setErrorMessage("Invalid Email.");
            if(/^\d{10}$/.test(Mobile) === "") return setErrorMessage("Please enter valid 10 digit mobile number.");
            if(Message == "") return setErrorMessage("Invalid Message.");
            setInProcess(true);
            axios.post(Endpoint+'/contactus/sendmessage',{FirstName,LastName,Email,Mobile,Message}).then(function (response) {
                if(response.data.errorcode !== 0) return setErrorMessage(response.data.errortext);
                setSuccessMessage(response.data.errortext);
                setFirstName("");
                setLastName("");
                setEmail("");
                setMobile("");
                setMessage("");
            }).catch(function (error) {
                console.log(error);
                setErrorMessage(error.message);
            }).then(function () {setInProcess(false);});
    }
	return (
        <div className="contact-widget-area pb-70">
            <div className="container">
                <div className="section-title text-center mb-45">
                    <span>SEND MESSAGE</span>
                    <h2>Feel Free to message us?</h2>
                </div>
                <div className="contact-form">
                    <form action="#" method="POST" id="contactForm" onSubmit={(event)=>{SendMessage();event.preventDefault();return false;}}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <input type="text" name="firstname" id="firstname" className="form-control" required data-error="Please Enter Your First Name" placeholder="First Name" value={FirstName} onChange={(e)=>{setFirstName(e.target.value);}}  />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <input type="text" name="lastname" id="lastname" className="form-control" required data-error="Please Enter Your Last Name" placeholder="Last Name" value={LastName} onChange={(e)=>{setLastName(e.target.value);}}  />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <input type="mobile" name="mobile" id="mobile" required data-error="Please Enter Your number" className="form-control" placeholder="Mobile Number" value={Mobile} onChange={(e)=>{setMobile(e.target.value);}} />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <input type="email" name="email" id="email" className="form-control" required data-error="Please Enter Your Email Address" placeholder="Email Address" value={Email} onChange={(e)=>{setEmail(e.target.value);}}  />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <textarea name="message" className="form-control" id="message" cols="30" rows="7" required data-error="Write your message" placeholder="Your Message" value={Message} onChange={(e)=>{setMessage(e.target.value);}}></textarea>
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-lg-12" style={{display:"none"}}>
                                <div className="agree-label">
                                    <input type="checkbox" id="chb1" />
                                    <label htmlFor="chb1"> Accept <Link to="/terms">Terms & Conditions</Link> And <Link to="/privacypolicy">Privacy Policy.</Link></label>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                {
                                    InProcess != true ?
                                    <button type="submit" className="default-btn">Send Message</button>
                                    :
                                    <button type="button" className="default-btn">Sending</button>
                                }
                                {SuccessMessage != "" && <div id="msgSubmit" className="h3 hidden" style={{color:"green",fontSize:12}}>{SuccessMessage}</div> }
								{ErrorMessage != "" && <div id="msgSubmit" className="h3 hidden" style={{color:"red",fontSize:12}}>{ErrorMessage}</div>}
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
	);
}
export default ContactForm;

