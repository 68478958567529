import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function EminentPersonalities(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <div className="courses-area pb-70">
            <div className="container">
                <div className="section-title text-center mb-45">
                    <h2>Eminent Personalities</h2>
                    <p>Here are few Eminent Personalities who has worked/contributed at Andhra Jatheeya Vidya Parishad.</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6" style={{display:"none"}}>
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/skins/"+skin+"/assets/images/courses/courses-img1.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Design</a>
                                <div className="price-text">$120</div>
                                <h3><a href="#">UI/UX design pattern for succesfull software applications</a></h3>
                                <ul className="course-list">
                                    <li><i className="ri-time-fill"></i> 10 hr 07 min</li>
                                    <li><i className="ri-vidicon-fill"></i> 67 lectures</li>
                                </ul>
                                <div className="bottom-content">
                                    <a href="#" className="user-area">
                                        <img src={"/skins/"+skin+"/assets/images/courses/courses-instructors1.jpg"} alt="Instructors" />
                                        <h3>David warner</h3>
                                    </a>
                                    <div className="rating">
                                        <i className="ri-star-fill"></i>4k+ rating
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-1.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Mrs. Annie Besant</a>
                                <div className="price-text"></div>
                                <h3><a href="#">Inspired by the objectives and ideologies of A J Kalasala, contributed 30000/- for development of Andhra Jatheeya Vidya Parishad.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-2.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Andhra Rathna <br/>Sri Duggirala Gopalakrishnayya</a>
                                <div className="price-text"></div>
                                <h3><a href="#">Has served as Principal at AJ Kalasala.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-3.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Sri Bezawada Gopala Reddy</a>
                                <div className="price-text"></div>
                                <h3><a href="#">Ex-Governer of UP, was an Alumni of A J Kalasala.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-4.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Sri C P Ramaswamy Iyer</a>
                                <div className="price-text"></div>
                                <h3><a href="#">Advocate-General of Madras Presidency from 1920 to 1923.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-5.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Sri Vishwanadha Satyanarayana</a>
                                <div className="price-text"></div>
                                <h3><a href="#">A great Poet and Gnanapeetha Awardee, Ex-lecturer in A J Kalasala.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-6.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Sri Adivi Bapiraju Garu</a>
                                <div className="price-text"></div>
                                <h3><a href="#">A renowned Painter and Writer, served as Principal of A J Kalasala.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-7.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Dr. Sarvepalli Radhakrishnan</a>
                                <div className="price-text"></div>
                                <h3><a href="#">Vice President of India President of Golden Jubilee celebrations (1961).</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="courses-item">
                            <a href="#">
                                <img src={"/images/emi/emi-8.jpg"} alt="Courses" />
                            </a>
                            <div className="content">
                                <a href="#" className="tag-btn">Sri Pingali Venkayya Garu</a>
                                <div className="price-text"></div>
                                <h3><a href="#">Renowned Freedom fighter who designed Indian National Flag, also a staff member at A J Kalasala.</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    );
}
export default EminentPersonalities;
