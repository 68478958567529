import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function HeroSection4(props) {
    const {header,description} = props;
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <div className="inner-banner inner-banner-bg7">
            <div className="container">
                <div className="inner-title text-center">
                    <h3>{header}</h3>
                    <ul>
                        <li>{description}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default HeroSection4;
