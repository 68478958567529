import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import ContactForm from '../components/ContactForm';
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

const InstituteNames = {ajkalasala:"Andhra Jatheeya Kalasala",ajkiti:"A.J.K Industrial Training Institute",ajkorientalsecondaryschool:"A.J.K Oriental Secondary School",ajcollegeofeducation:"AJ College of Education",ajkdiplomainseedtechnology:"A.J.K Diploma in Seed Technology"};
function AcademicsScreen(props) {
	const {skin} = useContext(GlobalContext);
    const {slug} = useParams();
	return (
		<>
			<Helmet><title>{InstituteNames[slug]}</title></Helmet>
            <ScrollToTop />
			<Header bgcolor={"gray"} />
            <div className="inner-banner inner-banner-bg9">
                <div className="container">
                    <div className="inner-title">
                        <h3>{InstituteNames[slug]}</h3>
                        <div className="rating"><i className="ri-star-fill"></i>4k+ Students</div>
                        <div className="inner-banner-content">
                            <Link to="/chairman" className="user-area">
                                <img src={"/images/team/chairman.jpg"} alt="Chairman" style={{height:30,width:30}}/>
                                <h3>Dr. K. Hanumantha Rao</h3>
                            </Link>
                            <ul className="course-list">
                                <li><i className="ri-time-fill"></i> 09:00 AM to 5:00PM</li>
                            </ul>
                        </div>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>{InstituteNames[slug]}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="courses-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            {slug == "ajkalasala" && 
                                <>
                                    <p><strong>Andhra Jatheeya Kalasala</strong> was established in the year 1910 under the leadership of our great dynamic leader Sri Kopalle Hanumantha Rao Panthulu garu. Being an educationist himself he understood the nuances of national education and had a great vision in developing the institution in a larger way.</p>
                                    <p>A J Kalasala is the most leading and oldest academic department of the Parishad. The objective of the Kalasala is to impart and promote the education on national lines and is designed to incorporate the best Oriental ideals of life and thought.</p>
                                    <p>The courses offered by A.J Kalasala are at the Intermediate and Graduation level.</p>
                                    <p>It is a composite college with Grant-in-aid and is recognized by UGC act under sections 12(b) and 2(f) status.</p>
                                    <p>The donations made to the Andhra Jatheeya Vidya Parishad are eligible for Income Tax Exemption under section 80G(5) as per the order of Income Tax Commissioner, Vijayawada.</p>
                                    <ContactForm />
                                </>
                            }
                            {slug == "ajkiti" && 
                                <>
                                    <p><strong>A.J.K Industrial Training Institute: </strong> Vocational and Industrial Training trades were part of the curriculum of the A J Kalasala since its inception in 1910.  These were being managed as A J Kalasala Engineering Workshop in those days.  However, due to modernization and changes in the field of education, these trades were required to be run as per the rules and procedures laid down by Director General of Employment and Training and National Council for Vocational Training, under the capacity of a private Industrial Training Institute.</p>
                                    <p>It marks a history of many great freedom fighters and leaders being a vital part of this ITI. The energy and the dynamism of today’s youth has to be utilised in a productive way to create a bright future for them as well as the country. The institution aims in utilizing and harnessing the potential of the students to the maximum to meet the demand of today’s markets. </p>
                                    <p>The A.J.K ITI takes the experience of both within and outside the classroom nurturing the creativity through challenging project works, participating, learning and providing an environment where the innovative ideas are translated into realities.</p>
                                    <p>The donations made to the Andhra Jatheeya Vidya Parishad are eligible for Income Tax Exemption under section 80G(5) as per the order of Income Tax Commissioner, Vijayawada.</p>
                                </>
                            }
                            {slug == "ajkorientalsecondaryschool" && <>
                                <p><strong>A.J.K Oriental Secondary School</strong> was established in the year 1959, with a view to focus in providing a useful and pragmatic education in oriental languages to one and all in the society. Sanskrit is taught as a second language and also one of the main subjects in the school. The school offers education from 6th Class to 10th Class (in Telugu Medium).</p>
                                <p>The school started with a very few number of students but gradually developed to a larger extent and has been growing further. A.J.K Oriental High School is not only trying for an excellence in the field of education but also striving hard for the overall development of the student.</p>
                                <p>The school aims in maintaining the culture in teaching our Vedic language, Sanskrit, to each student and has been successful in continuing to retain the age old culture and literature. </p>
                                <p>With the dwindling number of Teaching Staff, the management looked forward for support Under the Revised Scheme for Development of Sanskrit Education, 2007 Rashtriya Sanskrit Sansthan (Deemed University). It provides Financial Assistance for the Development of Sanskrit subject.</p>
                                <p>We are privileged to gain the opportunity in seeking support from the Grant-in-Aid Committee of Rashtriya Sanskrit Sansthan for the academic year 2015-2016 for 1 Modern teacher and 1 Sanskrit teacher. We are also trying Grants for other teachers under this scheme.</p>
                                <p>A.J.K Secondary School is a model school for A.J College of Education.</p>
                                <p>It is a Grant-in-aid School running as per the rules of Department of Secondary School Education, Andhra Pradesh.</p>
                                <p>The donations made to the Andhra Jatheeya Vidya Parishad are eligible for Income Tax Exemption under section 80G(5) as per the order of Income Tax Commissioner, Vijayawada.</p>
                            </>}
                            {slug == "ajcollegeofeducation" && <>
                                <p><strong>A J College of Education</strong> was established in the year 1967. It exhibits a rich and diversified culture of academic staff and students from all over India. Education at A.J College is comprehensive and aimed at developing the intellectual and personal strengths of the students. It has an excellent curriculum with innovative, pedagogy and experiential training.</p>
                                <p>A J. College of Education is an NCTE-approved comprehensive professional college providing specialized preparation in diverse areas of education on National lines. We offer an undergraduate program leading to a bachelor’s degree in Teacher Education since 1967. This institution is a recognized leader in guiding, shaping, and mentoring educational professionals (Teachers and Trainers), who will go forth with a lifelong love of learning and teaching.</p>
                                <p>We ensure our trainees acquire the skills essential for thriving in a global knowledge-based economy. Graduate programs in our College provide opportunities for advanced study and research in education and foster the development of innovative responses to the challenges in the field of education. They can be designed to meet licensure guidelines, licensing requirements, professional association recommendations, College and University requirements, and individual student goals.</p>
                                <div>
                                    The A. J. College of Education recognizes its responsibility to offer two types of graduate academic programs: <br/>
                                    •   Program that prepares educational scholars to further educational theory, policy, and research;<br/>
                                    •   Program that prepares educational practitioners for roles in public education.<br/><br/>
                                </div>
                                
                                <p>It is a Grand-in-aid College governed by the rules of N.C.T.E with affiliation to Krishna University. Though being an aided college, temporarily the B.Ed. Course having two units is running under the unaided category.</p>
                                <p>The ongoing M.Ed. Course that had started in 1994 has been revived and is sanctioned for an allotment of one unit under the M.Ed. programme for the year 2016-2017.</p>
                                <p>NCTE has issued a formal recognition for D.El.Ed course, from the academic year 2016-2017.</p>
                                <p>A J College of Education is recognized by UGC under sections of 12(b) and 2(f).</p>
                                <p>The donations made to the Andhra Jatheeya Vidya Parishad are eligible for Income Tax Exemption under section 80G(5) as per the order of Income Tax Commissioner, Vijayawada.</p>
                                <p>To know further details about us please visit our website <a href="http://www.ajcollegeofeducation.org/" target={"_blank"}>www.ajcollegeofeducation.org</a></p>
                            </>}
                            {slug == "ajkdiplomainseedtechnology" && <>
                                <div>
                                    This is inline with the founding objective of Andhra Jateeya Vidya Parishad and also in consideration of the below aspects: 
                                    •   Krishna District is one of the most progressive districts in Andhra Pradesh with respect to Agricultural Development - maintaining high levels of crop production compared to several other districts;<br/>
                                    •   Our motto provide education, mainly Agricultural Education in its broader sense and to promote various extension programs in Agriculture and Agricultural Production;<br/>
                                    •   Our aim to provide opportunities to the rural youth and women among others.The A. J. College of Education recognizes its responsibility to offer two types of graduate academic programs.<br/><br/>
                                </div>
                                <p>The institution has facilitate the student agriculturists by providing 2 acres of cultivable land with sufficient irrigation for harvesting various crops by them as part of curriculum.</p>
                            </>}
                        </div>
                        <div className="col-lg-4">
                            <div className="courses-details-sidebar">
                                <img src={"/images/academics/"+slug+".jpg"} alt={InstituteNames[slug]} />
                                <div className="content">
                                {slug == "ajkalasala" && 
                                    <>
                                        <h3>Established in the year 1910</h3>
                                        <span>Intermediate Courses:</span>
                                        <ul className="courses-details-list">
                                            <li>(Affiliated to Board of Intermediate Education, Andhra Pradesh)</li>
                                            <li>M.P.C (Maths, Physics, and Chemistry) in English and Telugu Medium.</li>
                                            <li>C.E.C (Civic, Economics and Commerce) in English and Telugu Medium.</li>
                                        </ul>
                                        <span>Graduation Courses:</span>
                                        <ul className="courses-details-list">
                                            <li>(Affiliated to Krishna University, Machilipatnam, Krishna Dist., AP)</li>
                                            <li>B.A (Bachelor of Arts) – H.E.P (History, Economics, Politics) – Telugu Medium</li>
                                            <li>B.Com (Bachelor of Commerce) – Telugu Medium</li>
                                            <li>B.Sc. (Bachelor of Science) MPC (Maths, Physics, Chemistry) – Telugu Medium.</li>
                                        </ul>
                                    </>
                                }
                                {slug == "ajkiti" && <>
                                    <h3>Established in the year 1910</h3>
                                    <span>The trades offered by the ITI are:</span>
                                    <ul className="courses-details-list">
                                        <li>1.  Electronics Trade</li>
                                        <li>2.  Diesel Mechanic Trade</li>
                                        <li>3.  Fitter Trade</li>
                                    </ul>
                                </>}
                                {slug == "ajkorientalsecondaryschool" && <>
                                    <h3>Established in the year 1959</h3>
                                    <span>The school offers:</span>
                                    <ul className="courses-details-list">
                                        <li>2.  6th Class to 10th Class (Telugu Medium)</li>
                                        <li>1.  Vedic language, Sanskrit as a subject</li>
                                    </ul>
                                </>}
                                {slug == "ajcollegeofeducation" && <>
                                    <h3>Established in the year 1967</h3>
                                    <span>The courses offered by the Institution are    :</span>
                                    <ul className="courses-details-list">
                                        <li>B.Ed. (Bachelor of Education)</li>
                                        <li>D.El.Ed. (Diploma in Elementary Education)</li>
                                        <li>M.Ed. (Master of Education)</li>
                                    </ul>
                                </>}
                                {slug == "ajkdiplomainseedtechnology" && <>
                                    <h3>From 2017-18</h3>
                                    <span>Andhra Jateeya Vidya Parishad started <strong>A.J.K Diploma in Seed Technology</strong> from the academic year 2017-18 with an intake of 40 students per year under the affiliation of Acharya N G Ranga Agricultural University, Guntur.</span>
                                </>}
                                    <Link to="/contact" className="default-btn">Contact Us</Link>
                                    <ul className="social-link">
                                        <li className="social-title">Share this course:</li>
                                        <li><FacebookShareButton url={window.location.href}><a href="javascript:;" target="_blank"><i className="ri-facebook-fill"></i></a></FacebookShareButton></li>
                                        <li><TwitterShareButton url={window.location.href}><a href="javascript:;" target="_blank"><i className="ri-twitter-fill"></i></a></TwitterShareButton></li>
                                        <li><LinkedinShareButton url={window.location.href}><a href="https://www.pinterest.com/" target="_blank"><i className="ri-linkedin-line"></i></a></LinkedinShareButton></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default AcademicsScreen;