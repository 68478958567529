import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function NotFoundScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>404 - Page Not Found</title></Helmet>
			<ScrollToTop />
			<Header />
			<HeroSection4 header={"404 Error Page"} description={"Seems you are Lost, Don't worry, you can start From Home page."} />
			<div className="error-area ptb-100">
				<div className="d-table">
					<div className="d-table-cell">
						<div className="error-content">
							<h1>4 <span>0</span> 4</h1>
							<h3>Oops! Page Not Found</h3>
							<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
							<Link to="/" className="default-btn">Return To Home Page</Link>
						</div>
					</div>
				</div>
			</div>
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default NotFoundScreen;

