import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/css/modal-video.css';

import { GlobalContext } from '../../../GlobalContext';
function HeroSection3(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    const [OpenVideo,setOpenVideo] = useState(false);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <div className="hero-slider-area">
            <div className="hero-slider">
                <div className="hero-item">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="hero-content">
                                    <span className="top-title">Estd.In 1907</span>
                                    <h1>Andhra Jatheeya Vidya Parishad</h1>
                                    <p>The Kalasala itself was an offspring of the Vande Mataram Movement of 1906. Naturally the Non-Co-operation Movement enthused the patriarch founder of the Kalasala.</p>
                                    <div className="banner-btn">
                                        <a href="/contact" className="default-btn border-radius-50">Contact Us</a>
                                        {/* <ModalVideo channel='youtube' autoplay isOpen={OpenVideo} videoId="AfvAZs2TjpE" onClose={() => setOpenVideo(false)} />
                                        <a href="javascript:;" onClick={()=>{setOpenVideo(true);}} className="play-btn">
                                            <i className='flaticon-play-button-arrowhead'></i>
                                            <span className="title-text">Watch video</span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="hero-img">
                                    <img src={"/images/buildings/AJ-Kalasala.jpg"} className="hero" alt="Hero" />
                                    <div className="hero-bg-shape">
                                        <img src={"/skins/"+skin+"/assets/images/home-three/bg-shape1.png"} className="bg-img-shape1" alt="Hero" />
                                        <img src={"/skins/"+skin+"/assets/images/home-three/bg-shape2.png"} className="bg-img-shape2" alt="Hero" />
                                    </div>
                                    <div className="top-content">
                                        <div className="hero-img-content">
                                            <i className="flaticon-student"></i>
                                            <div className="content">
                                            <h3>1250+</h3>
                                            <p>Students</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <div className="hero-img-content">
                                            <i className="flaticon-checked"></i>
                                            <div className="content">
                                                <h3>115+</h3>
                                                <p>Years Experience</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-shape">
                <div className="shape1">
                    <img src={"/skins/"+skin+"/assets/images/home-three/shape.png"} alt="Shape" />
                </div>
                <div className="shape2">
                    <img src={"/skins/"+skin+"/assets/images/home-three/shape2.png"} alt="Shape" />
                </div>
            </div>
        </div>
    );
}
export default HeroSection3;
