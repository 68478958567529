import React, {useContext, useState, useEffect, } from 'react';
import { Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection3 from "../components/HeroSection3";
import StatsSection1 from "../components/StatsSection1";
import AcademicsSection1 from "../components/AcademicsSection1";
import EminentPersonalities from "../components/EminentPersonalities";
import OurInstructors from "../components/OurInstructors";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function HomeScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet>
				<title>Welcome to Andhra Jatheeya Vidya Parishad</title>
			</Helmet>
			<ScrollToTop />
			<Header bgcolor={"gray"} />
			<HeroSection3 />
			<StatsSection1 />
			<AcademicsSection1 />
			<EminentPersonalities />
			<BecomeSupportPartnerSection1 />
			<br/>
			<OurInstructors />
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default HomeScreen;

