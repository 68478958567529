import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function BecomeSupporter(props) {
	const {Endpoint} = useContext(GlobalContext);
    const [InProcess,setInProcess] = useState(false);
    const [showBankAccount,setShowBankAccount] = useState(false);
    const [fullName,setFullName] = useState("");
    const [email,setEmail] = useState("");
    const [mobile,setMobile] = useState("");
    const [amount,setAmount] = useState('');
    const [paymentMethod,setPaymentMethod] = useState("ANY");
    const [SuccessMessage,setSuccessMessage] = useState("");
    const [ErrorMessage,setErrorMessage] = useState("");
    const initiatePayment = async ()=>{
        //if(InProcess == true) return;
        setSuccessMessage("");
        setErrorMessage("");
        
        if(/^([a-zA-Z ]){2,30}$/.test(fullName) === "") return setErrorMessage("Please enter valid full name.");
        if(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) === "") return setErrorMessage("Invalid email.");
        if(/^\d{10}$/.test(mobile) == "") return setErrorMessage("Please enter valid 10 digit mobile number.");
        if(paymentMethod === "") return setErrorMessage("Please select payment method.");
        if(amount === "") return setErrorMessage("Please enter amount.");
        if(isNaN(amount)) return setErrorMessage("Please enter valid amount.");

        setInProcess(true);
        axios.post(Endpoint+'/payment/initiate',{fullName, email, mobile, amount, paymentMethod}).then(function (response) {
            if(response.data.errorcode !== 0) {
                return setErrorMessage(response.data.errortext);
            }

            // setSuccessMessage(response.data.errortext);
            // setFullName("");
            // setEmail("");
            // setMobile("");
            // setPaymentMethod("");
            // setAmount("");

            if(response.data.script) {
                eval(response.data.script);
            } else {
                setShowBankAccount(true);
            }
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.message);
        }).finally(function () {setInProcess(false);});
    }
	return (
		<>
			<Helmet><title>Become Supporter</title></Helmet>
            <ScrollToTop />
			<Header bgcolor={"gray"} />
            <div className="courses-details-area section-bg pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="play-btn-area-two">
                                <a href="#" class="play-btn">
                                <i class="flaticon-play-button-arrowhead"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="register-form">
                                <div class="top-header">
                                    <h3>Donate Now</h3>
                                    <span>Help us protect 115+ Old institution</span>
                                </div>
                                <form class="register-form-max" action="#" method="POST" id="donateForm" onSubmit={(event)=>{initiatePayment();event.preventDefault();return false;}}>
                                    {
                                        showBankAccount !== true ?
                                        <>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" required="" data-error="Please Enter Your Name" placeholder="Your full name" value={fullName} onChange={(e)=>{setFullName(e.target.value);}} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" required="" data-error="Please Enter Email" placeholder="Email address" value={email} onChange={(e)=>{setEmail(e.target.value);}} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" required="" data-error="Please Enter mobile" placeholder="Mobile number" value={mobile} onChange={(e)=>{setMobile(e.target.value);}} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <input type="number" class="form-control" required="" data-error="Please enter amount" placeholder="Amount" value={amount} onChange={(e)=>{setAmount(e.target.value);}} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 " style={{display:'none'}}>
                                                    <div class="form-group">
                                                        <select class="form-control" required="" data-error="Please select payment method" value={paymentMethod} onChange={(e)=>{setPaymentMethod(e.target.value);}} >
                                                            <option value="">Select Payment Method</option>
                                                            <option value="ANY">Any</option>
                                                            <option value="DD">Bank Deposit/Transfer</option>
                                                            <option value="DC">Debit Card</option>
                                                            <option value="CC" disabled>Credit Card</option>
                                                            <option value="NB" disabled>Netbanking</option>
                                                            <option value="UPI" disabled>UPI</option>
                                                            <option value="QR" disabled>QR</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    {InProcess ? <button type="button" className="default-btn border-radius-50">Please Wait</button>:<button type="submit" class="default-btn border-radius-50">Donate</button>}
                                                    {SuccessMessage !== "" && <div id="msgSubmit" className="h3 hidden" style={{color:"green",fontSize:12}}>{SuccessMessage}</div> }
                                                    {ErrorMessage !== "" && <div id="msgSubmit" className="h3 hidden" style={{color:"red",fontSize:12}}>{ErrorMessage}</div>}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <p>Please make a Deposit to below back account and email to receipt to <a href="mailto:contact@ajvidyaparishad.org">contact@ajvidyaparishad.org</a>.</p>
                                            <div class="billing-totals">
                                                <h3>Bank Account Details</h3>
                                                <ul>
                                                    <li>Account No <span>027210100118926</span></li>
                                                    <li>Bank <span>UNION BANK OF INDIA</span></li>
                                                    <li>Branch <span>Javvarpeta, Machilipatnam</span></li>
                                                    <li>IFSC Code <span>UBIN0802727</span></li>
                                                </ul>
                                            </div>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default BecomeSupporter;