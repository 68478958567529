import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function Footer(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <footer className="footer-area">
            <div className="container pt-100 pb-70">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <Link to="/">
                                    <img src={"/skins/"+skin+"/assets/images/logos/logo-2.png"} alt="Images" />
                                </Link>
                            </div>
                            <p>
                            The Kalasala itself was an offspring of the Vande Mataram Movement of 1906. Naturally the Non-Co-operation Movement launched by Gandhiji enthused the patriarch founder of the Kalasala.
                            </p>
                            <ul className="social-link">
                                <li className="social-title">Follow Us:</li>
                                <li><a href="https://www.facebook.com/" target="_blank"><i className="ri-facebook-fill"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                                <li><a href="https://www.pinterest.com/" target="_blank"><i className="ri-instagram-line"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget ps-5">
                            <h3>About us</h3>
                            <ul className="footer-list">
                                <li><Link to="/eminentpersonalities">Eminent Personalities</Link></li>
                                <li><Link to="/aboutus">About Us</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/terms">Terms</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget ps-5">
                        <h3>Academics</h3>
                            <ul className="footer-list">
                                <li><Link to="/academics/ajkalasala">AJ Kalasala</Link></li>
                                <li><Link to="/academics/ajkiti">A.J.K I.T.I</Link></li>
                                <li><Link to="/academics/ajkorientalsecondaryschool">A.J.K Oriental Secondary School</Link></li>
                                <li><Link to="/academics/ajcollegeofeducation">AJ College of Education</Link></li>
                                <li><Link to="/academics/ajkdiplomainseedtechnology">A.J.K Diploma in Seed Technology</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget ps-5">
                            <h3>Official Info</h3>
                            <ul className="footer-contact">
                                <li>
                                    <i className="ri-map-pin-2-fill"></i>
                                    <div className="content">
                                        <h4>Location:</h4>
                                        <span>Raju Peta, Machilipatnam,Krishna District, Andhra Pradesh - 521 001.</span>
                                    </div>
                                </li>
                                <li>
                                    <i className="ri-mail-fill"></i>
                                    <div className="content">
                                        <h4>Email:</h4>
                                        <span><a href="mailto:ajvpmtm@ajvidyaparishad.org">ajvpmtm@ajvidyaparishad.org</a></span>
                                    </div>
                                </li>
                                <li>
                                <i className="ri-phone-fill"></i>
                                    <div className="content">
                                        <h4>Phone:</h4>
                                        <span><a href="tel:08672229000">08672-229000</a></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                <div className="copyright-area">
                <div className="container">
                    <div className="copy-right-text text-center">
                    <p>All Rights Reserved <a href="/" target="_blank">A J Vidya Parishad</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;
