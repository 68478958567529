import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function ChairmanScreen() {
	const {skin,Endpoint} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Chairman's Desk!</title></Helmet>
            <ScrollToTop />
			<Header />
			<HeroSection4 header={"Chairman's Desk!"} description={"Chairman and Founder Family Member."} />
			<div className="instructors-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="instructors-details-img">
                                <img src={"/images/team/chairman.jpg"} alt="Chairman" />
                                <ul className="social-link">
                                    <li className="social-title">Follow me:</li>
                                    <li><a href="https://www.facebook.com/" target="_blank"><i className="ri-facebook-fill"></i></a></li>
                                    <li><a href="https://twitter.com/" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                                    <li><a href="https://www.pinterest.com/" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="instructors-details-content pl-20">
                                <h3>Dr. K. Hanumantha Rao</h3>
                                <span className="sub-title">Chairman and Founder Family Member</span>
                                <ul>
                                    <li>Phone number: <span><a href="tel:+9108672229000 ">+91 08672 - 229000</a></span></li>
                                    <li>Email: <span><a href="mailto:chairman@ajvidyaparishad.org">chairman@ajvidyaparishad.org</a></span></li>
                                    <li>Address: <span>Raju Peta</span></li>
                                    <li>City/District: <span>Machilipatnam,Krishna District</span></li>
                                    <li>State/Pincode: <span>Andhra Pradesh - 521 001</span></li>
                                </ul>
                                <p>
                                    As our Mission aptly and briefly talks about “Efficiency” and “Competency” we believe 
                                    in creating future leaders not only in academics but also in every domain of the student’s 
                                    life. Our aim is to build an effervescent team among the aspiring students and the efficient 
                                    faculty. The institution visualizes to leave no stone unturned in aspiring to contribute to 
                                    the legacy of its history.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="courses-area pb-70">
                <div className="container">
                    <div className="section-title text-center mb-45"><h2>Biography</h2></div>
                    <div className="section-title mb-45">
                        <p>All this has been possible with the combined effort and synergy of all the founder family members. The commitment is to extend the willingness and put strenuous and combined efforts for the growth of this institution which will lead it into the path of greatness.</p>
                        <p>Our institution ensures that it inculcates the best ideals and ethics in students to make them pioneers, as our founder and epitome of culture, Sri Kopalle Hanumantha Rao Panthulu Garu had envisioned. Today, our institution is fortunate to be in the position where it will strive and can overcome the challenges it faces pragmatically, and make this a learning experience for one and all.</p>
                        <p>The founder family members who are all associated with the history of this college have always been supportive. The sincerity and hard work of all these people fosters the development of this institution. It will always be a constant endeavour of the Institution to nurture an environment of mutual respect in the constant process of achieving success. We look forward to all your continued help and cooperation in all aspects.</p>                
                    </div>
                </div>
            </div>
			<BecomeSupportPartnerSection1 />
			<Footer />
		</>
	);
}
export default ChairmanScreen;
