import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function BecomeSupportPartnerSection1(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <div className="enrolled-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="enrolled-content">
                            <div className="section-title">
                                <h2>Become Support Partner</h2>
                                <p>Provide financing support to help 115+ year old institute.</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-6">
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> Tax Exemption under 80G(5)</li>
                                        <li><i className="flaticon-check"></i> Endowments Department's Trust</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> 115+ Years</li>
                                        <li><i className="flaticon-check"></i> Best Management</li>
                                    </ul>
                                </div>
                            </div>
                            <Link to="/contact" className="default-btn">Contact Us</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="enrolled-img" data-speed="0.05" data-revert="true">
                            <img src={"/skins/"+skin+"/assets/images/enrolled/enrolled-img4.png"} alt="Enrolled" />
                            <div className="bg-shape">
                                <img src={"/skins/"+skin+"/assets/images/enrolled/enrolled-shape.png"} alt="Shape" />
                            </div>
                            <div className="top-content">
                                <div className="enrolled-img-content">
                                    <i className="flaticon-mail-inbox-app"></i>
                                    <div className="content">
                                        <h3>Support</h3>
                                        <p>Send your Support!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="right-content">
                                <div className="enrolled-img-content">
                                    <i className="flaticon-discount"></i>
                                    <div className="content">
                                       <h3>Tax Exemption</h3>
                                        <p>Exemption under 80G(5)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="left-content">
                                <div className="enrolled-img-content">
                                    <i className="flaticon-reading-book active"></i>
                                    <div className="content">
                                        <h3>1250+ students</h3>
                                        <p>Needs your Support</p>
                                    </div>
                                </div>
                            </div>
                            <div className="enrolled-img-shape">
                                <div className="shape1">
                                    <img src={"/skins/"+skin+"/assets/images/enrolled/enrolled-shape2.png"} alt="Shape" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BecomeSupportPartnerSection1;
