import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function TermsScreen() {
	const {skin,Endpoint} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Terms & Conditions :: A J Vidya Parishad</title></Helmet>
            <ScrollToTop />
			<Header />
			<HeroSection4 header={"Terms & Conditions"} description={"Last Update: 2023-15-01"} />
			<div className="instructors-details-area pt-100 pb-70">
                <div className="container">
                    <div class="section-title text-center">
                        <span>Terms & Conditions</span>
                        <h2>A J Vidya Parishad :: Terms & Conditions</h2>
                    </div>
                    <div class="row pt-45">
                        <div class="col-lg-12">
                            <div class="single-content">
                                <h3>Legal Disclaimer</h3>
                                <p>These rules and regulations (“Terms and Conditions”) shall be binding on each participant (“Donor”) who voluntarily desires to make a monetary donation to assist A J Vidya Parishad in Indian National Rupees (INR / ₹).</p>
                                <p>This document is an electronic record and is generated by a computer system and does not require any physical or digital signatures.</p>
                            </div>
                            <div class="single-content">
                                <h3>I. Eligibility</h3>
                                <p>You are eligible to make a donation if (i) you have attained the age of majority i.e. eighteen (18) years or (ii) you are a company/proprietorship or any other entity duly registered/incorporated under the laws of India.</p>
                                <p>Payment through online transfer can be made on this website (ajvidyaparishad.org) and following the instructions therein to make the donations upon which the Donor shall be redirected to the payment gateway to complete the Donation transaction. The Donations received through this payment mechanism shall be directly deposited into the A J Vidya Parishad account.</p>
                                <p>The Donor shall also be bound by the terms and conditions of the payment gateway service provider while making donations.</p>
                                <p>For purposes of these Terms and Conditions, “receipt/processing” of a donation occurs when the Bank Website server records the Donation information upon the Donor clicking “Donate/Submit/Process” button.</p>
                            </div>
                            <div class="single-content">
                                <h3>II. Personal Information</h3>
                                <p>Your donation may result in sharing of your personal information including but not limited to your Name, Phone Number/Email ID, PAN, Credit and/or Debit Card. (Read A J Vidya Parishad’s Privacy Policy here)</p>
                            </div>
                            <div class="single-content">
                                <h3>III. Tax Deduction Benefit</h3>
                                <b>A Donation is eligible for tax deduction benefits under Section 80 G of the Indian Income Tax Act, 1961 if the Donor is (i) above the age of eighteen (18) years and (ii) a resident of India (as per the Income Tax Act, 1961).</b>
                                <p>A J Vidya Parishad has a valid and subsisting registration under Section 80G of the Indian Income Tax Act, 1961.</p>
                                <p>A J Vidya Parishad is under Endowments Department</p>
                            </div>
                            <div class="single-content">
                                <h3>IV. Cancellation and Refund Policy</h3>
                                <p>We take the utmost care to process donations as per the instructions given by our donors, online and offline. However, in case of an unlikely event of an erroneous donation, we will respond to the donor within 14 working days of receiving a valid request for refund from the donor.</p>
                                <p>The timely refund of the donation will depend upon the type of credit card/banking instrument used during the transaction. The donor will have to send us a written request for a refund within 2 days of making the donation along with-</p>
                                <ul>
                                    <li>Proof of the deduction of the donation amount.</li>
                                    <li> In cases where the donation receipt has already been issued to the donor, the donor will have to return the original receipt to us at our office address.</li>
                                    <li>If the tax exemption certificate is already issued, we will not be able to refund the donation. However, in case of a valid refund request due to any error on our part, we will refund the donation and bear the costs of the same.</li>
                                </ul>
                                <p>If the donor doesn’t write within two days, then refund is not possible.</p>
                            </div>
                            <div class="single-content">
                                <h3>V. Amendments to the Terms and Conditions</h3>
                                <p>A J Vidya Parishad reserves the right to change these terms and conditions at any time and without prior notice by posting changes online. Please check back from time to time so you are aware of any changes or updates to the Terms and Conditions. You may reach out to us if you have any queries about any changes made to our practices.</p>
                                <p>We understand your concerns about safety and security and assure you that we make every effort to safeguard your <a href="/privacy-policy">privacy</a> when you make donations through our system.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<BecomeSupportPartnerSection1 />
			<Footer />
		</>
	);
}
export default TermsScreen;
