import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import Header from "./Header";
import HeroSection4 from "./HeroSection4";
import SubscribeToNewletters1 from "./SubscribeToNewletters1";
import Footer from './Footer';

import { GlobalContext } from '../../../GlobalContext';

function InfrastructureSection1() {
	const {skin} = useContext(GlobalContext);
	return (
        <div className="enrolled-area-two pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="enrolled-img-three mb-30 pr-20">
                            <img src={"/images/buildings/infrastracture.jpg"} alt="Enrolled" />
                            <div className="enrolled-img-content">
                                <i className="flaticon-discount"></i>
                                <div className="content">
                                    <h3>AJK</h3>
                                    <p>Andhra Jatheeya Kalasala</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="enrolled-content mb-30">
                            <div className="section-title">
                                <span>A J Vidya Parishad</span>
                                <h2>Infrastructure</h2>
                                <p>“To impart and promote the imparting of Education, Literary, Scientific, Technical and Professional on nation lines and exclusively under national control- attaching special importance to a knowledge of the country. It’s literary, history and philosophy and designed to incorporate with the best Oriental ideals of life and thought the best assimilable ideals to inspire students with a genuine love for their country and a real desire to serve it.” In soliciting the support from the public, the Executive Committee of the Parishad had announced that “The institution does not identify itself with any particular school of social, political or religious thought.”.</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-6">
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> The Institution is having 290.26 acres rich cultivable land.</li>
                                        <li><i className="flaticon-check"></i> There are 5 buildings (4 buildings constructed with 2 floors and one building with 1 floor).</li>
                                        <li><i className="flaticon-check"></i> 21 Classrooms are available with sufficient infrastructure.</li>
                                        <li><i className="flaticon-check"></i> 13 Laboratories (Physics-3, Botany-2, Zoology-2, Computer Science-1 and Language Laboratories) are available.</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> 1 Library block with built in are of 5888 square feet.</li>
                                        <li><i className="flaticon-check"></i> 1 Seminar hall with a capacity of more than 100.</li>
                                        <li><i className="flaticon-check"></i> 1 Auditorium with a built in area of 2067 Square feet and seating capacity of 500.</li>
                                        <li><i className="flaticon-check"></i> 34 acres of vast & spacious playground.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}
export default InfrastructureSection1;

