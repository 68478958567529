import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function PaymentUnknownScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Processing your payment</title></Helmet>
			<ScrollToTop />
			<Header />
			<div className="error-area ptb-100">
				<div className="d-table">
					<div className="d-table-cell">
						<div className="error-content">
							<img src={"/skins/"+skin+"/assets/images/unknown.png"} style={{height:180}} alt="Shape" />
							<h2>Payment status unknown</h2>
							<p>Not to worry! We are looking into this.</p>
							<p>You will receive an email with the payment status soon.</p>
						</div>
					</div>
				</div>
			</div>
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default PaymentUnknownScreen;

