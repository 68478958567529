import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function ContactDetailsSection1() {
	const {skin} = useContext(GlobalContext);
	return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4  col-12 col-sm-8">
                        <div className="contact-info-card">
                            <i className="ri-map-pin-fill"></i>
                            <h3>Our location </h3>
                            <p>Raju Peta, Machilipatnam,</p>
                            <p>Andhra Pradesh - 521 001.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="contact-info-card">
                            <i className="ri-mail-fill"></i>
                            <h3>Email us</h3>
                            <p><a href="mailto:ajvpmtm@ajvidyaparishad.org">ajvpmtm@ajvidyaparishad.org</a></p>
                            <p><a href="mailto:contact@ajvidyaparishad.org">contact@ajvidyaparishad.org</a></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="contact-info-card">
                            <i className="ri-phone-fill"></i>
                            <h3>Phone</h3>
                            <p><a href="tel:08672229000">08672-229000</a></p>
                            <p><a href="tel:+919491000727">+91 9491000727</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}
export default ContactDetailsSection1;

