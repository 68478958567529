import React, {useContext, useState, useEffect, } from 'react';
import { Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import HomeScreen from "../screens/Home";
import AboutusScreen from "../screens/Aboutus";
import ContactusScreen from '../screens/Contactus';
import NotFoundScreen from "../screens/NotFoundScreen";
import VerifyNewsletterSubscriptionScreen from '../screens/VerifyNewsletterSubscription';
import FounderScreen from '../screens/Founder';
import ChairmanScreen from '../screens/Chairman';
import CorrespondentScreen from '../screens/CorrespondentScreen';
import ExecutiveOfficerScreen from '../screens/ExecutiveOfficerScreen';

import EminentPersonalitiesScreen from '../screens/EminentPersonalities';
import InfrastructureScreen from '../screens/Infrastructure';
import VisitorsScreen from '../screens/Visitors';
import AluminiScreen from '../screens/Alumini';
import GalleryScreen from '../screens/Gallery';
import AcademicsScreen from '../screens/Academics';
import TermsScreen from '../screens/Terms';
import PrivacyPolicyScreen from '../screens/PrivacyPolicy';
import BecomeSupporter from '../screens/BecomeSupporter';
import PaymentSuccessScreen from '../screens/PaymentSuccess';
import PaymentFailureScreen from '../screens/PaymentFailure';
import PaymentUnknownScreen from '../screens/PaymentUnknown';

import { GlobalContext } from '../../../GlobalContext';

function Root() {
	const {skin} = useContext(GlobalContext);
	useEffect(()=>{
		$(window).ready(function () {
			$('#preloader').delay(100).fadeOut('fade');
		  });
	},[]);
	return (
		<HelmetProvider>
			<Helmet>
				<title>Welcome</title>
				<link rel="icon" href={"/skins/"+skin+"/assets/img/favicon.png"} type="image/png" sizes="16x16" />
			</Helmet>
            <div id="preloader">
                <div id="preloader-area">
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                    <div className="spinner"></div>
                </div>
                <div className="preloader-section preloader-left"></div>
                <div className="preloader-section preloader-right"></div>
            </div>
			<Routes>
				<Route path={"/"} exact element={<HomeScreen />} />
				<Route path={"/aboutus"} exact element={<AboutusScreen />} />
				<Route path={"/contact"} exact element={<ContactusScreen />} />
				<Route path={"/verifyemailsubscription/:verifykey"} exact element={<VerifyNewsletterSubscriptionScreen />} />
				<Route path={"/founder"} exact element={<FounderScreen />} />
				<Route path={"/chairman"} exact element={<ChairmanScreen />} />
				<Route path={"/correspondent"} exact element={<CorrespondentScreen />} />
				<Route path={"/executiveofficer"} exact element={<ExecutiveOfficerScreen />} />

				<Route path={"/eminentpersonalities"} exact element={<EminentPersonalitiesScreen />} />
				<Route path={"/infrastructure"} exact element={<InfrastructureScreen />} />
				<Route path={"/alumini"} exact element={<AluminiScreen />} />
				<Route path={"/visitors"} exact element={<VisitorsScreen />} />
				<Route path={"/gallery"} exact element={<GalleryScreen />} />
				<Route path={"/academics/:slug"} exact element={<AcademicsScreen />} />
				<Route path={"/terms"} exact element={<TermsScreen />} />
				<Route path={"/privacy-policy"} exact element={<PrivacyPolicyScreen />} />
				<Route path={"/become-supporter"} exact element={<BecomeSupporter />} />
				<Route path={"/payment-success"} exact element={<PaymentSuccessScreen />} />
				<Route path={"/payment-failure"} exact element={<PaymentFailureScreen />} />
				<Route path={"/payment-unknown"} exact element={<PaymentUnknownScreen />} />
				<Route path="*" element={<NotFoundScreen />} />
			</Routes>
		</HelmetProvider>
	);
}
export default Root;
