import React, {useContext, useState, useEffect, } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
import ContactForm from '../components/ContactForm';

function AluminiScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet>
				<title>Reg As Alumini</title>
			</Helmet>
            <ScrollToTop />
			<Header bgcolor={"gray"} />
			<HeroSection4 header={"REG. AS ALUMINI"} description={"Andhra Jatheeya Vidya Parishad, Machilipatnam is an institution that has the reputation of serving in the field of National Education for over 100 Years. It is currently an organization which runs 4 subsidiary institutions that cater to the educational needs of the various sections in the society. A brief about our institution is given below."}/>
            <div className="blog-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details-content pr-20">
                                <div className="blog-preview-img">
                                    <img src={"/images/buildings/alumini.jpg"} alt="Blog Details" />
                                </div>
                                <h2>Conception of the Andhra Jatheeya Vidya Parishad</h2>
                                <p>Sri Kopalle Hanumantha Rao Panthulu Garu was a renowned educationist and stalwart freedom fighter in the time of Pre-Independent India. He actively participated in the Indian Freedom Movement by closely associating with Sri Lala Lajapathi Roy, Sri Bala Gangadhar Tilak and Sri Bipin Chandra Pal (Lal-Bal-Pal).  He also led the Indian National Congress in Andhra Pradesh before Gandhian era.  The conglomeration of his ideologies towards Swaraj as also the Swadesi Movement and in response to the nation’s call to make India self-sufficient, he put forward a constructive work by bequeathing his family property for the establishment of a flagship institution known as Andhra Jatheeya Vidya Parishad, on 17th November, 1907 and started the Andhra Jatheeya Kalasala (popularly known as National College) to propagate/promote National Education. The Parishad itself was registered under the Act XXI of 1860, on 19-6-1911. The registration of the Parishad helped engender a sense of security and confidence in the public mind, regarding the institution. It shows an explicit example of teamwork of the founder Sri Kopalle Hanumantha Rao Panthulu Garu and others.</p>
                                <p >The foundation stone for the construction of the Kalasala building was laid on March 25th 1909 in the 24 acres of land donated by Sri Kopalle Krishna Rao Panthulu garu. The institution was inaugurated by “Desabhaktha” Sri Konda Venkatappayya Panthulu garu on 27th March, 1910. Sri Kopalle Hanumantha Rao Panthulu Garu served as the Founder Principal of the Andhra Jatheeya Kalasala and put strenuous efforts in collating funds required to run the institution for many years. Inspired with his commitment, dedication and patriotic fervour in the establishment of the Institution, a number of people contributed their mite.</p>
                                <p>The Indian National Congress donated an amount of Rs. 27000/- in the year 1920-21 and Anne Besant donated an amount of Rs. 30000/- which helped the institution.</p>
                                <p >The Father of the Nation, Mahatma Gandhi, who visited Machilipatnam twice, had cherished spending a couple of days at the Kalasala premises. A few lines he had written in the visitors’ book on April 3, 1921 are still considered a driving force. An inscription of his valuable words at his memorial on the Kalasala premises has been inspiring and safeguarding the institution. These words promise delivery of high standard education.</p>
                                <blockquote className="blockquote">
                                    <i className="flaticon-quotation"></i>
                                    <p style={{paddingLeft:10}}>“I have passed two quiet amnd what shall always remain with me sacred days on the sacred grounds of this great educational institution.  The expectation that I had formed of the institution, has been more than realized. I see method, organizing ability and sacrifice written on every inch of these grounds. As an Indian I feel proud of it.  As the inhabitants of this great Andhra Province, I hope that all my Andhra friends are equally proud of it, and I hope that you citizens of Masulipatnam, consider it a privilege that you have an institution in your midst, which is manned by men, who are filled with the spirit of sacrifice. I ask you therefore to make this institution your own, strengthen it where you may find it to be weak, strengthen it further, where you may find that there is strength in it. Look up to it as an ideal and try to perfect it in order to perfect your own ideals.“</p>
                                </blockquote>
                                <p>What started as a result of National Education Movement in the pre-Gandhian era, has served our nation by providing quality education to many generations.  It has produced many scholars, educationists, doctors, engineers and leaders who served the nation in all walks of life.  Bezawada Gopala Reddy, Surisetty Anjaneyulu and Nori Dattatreyudu are a few notable persons from our Alumni.  Eminent scholars like Kavisamrat Viswanatha Satyanarayana garu (Gnanapeetha Awardee), Pingali Venkaiah garu (designer of the Indian National Flag), Adivi Bapiraju garu, and many leaders like Mutnuri Krishna Rao, Bhogaraju Pattabhi Sitaramaiah have all served the institution.</p>
                                <h2>The Current Condition of the Institution and Call for Support</h2>
                                <p>Since 1988, Andhra Jatheeya Vidya Parishad is registered under Act 30 of 1987 Andhra Pradesh Hindu Religious, Charitable and Endowments Act. It is under the Administrative Control of Commissioner, Endowments Department, Andhra Pradesh.</p>
                                <p >Since 1999, The Andhra Jatheeya Vidya Parishad is being managed by Dr. Kopalle Hanumantha Rao, the recognized Founder Family Member of the institution. The Endowments Department Official functions as the Executive Officer of the Vidya Parishad and Correspondent for all the subsidiary institutions under the lawful directions of the Founder Family Member. The present management is making efforts towards reviving the past glory under the guidance of the Commissioner, Endowments Department, Andhra Pradesh.</p>
                                <p>Andhra Jatheeya Vidya Parishad has seen many ups and downs and twists and turns in the course of its history. The glory of its grandeur has been fading slowly, year after year. In the current condition, our institution is still standing tall and calling for support from philanthropists and patrons, to continue and expand its legacy.</p>
                                <div className="enrolled-content">
                                    <div className="section-title">
                                        <p>The logistic support assessed and needing funds basically includes:</p>
                                    </div>
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> Construction of new building for AJK Oriental Secondary School</li>
                                        <li><i className="flaticon-check"></i> Construction of an Administrative Block for Andhra Jatheeya Vidya Parishad</li>
                                        <li><i className="flaticon-check"></i> Provide the required computer infrastructure in the</li>
                                    </ul>
                                </div>
                                <div className="enrolled-content">
                                    <div className="section-title">
                                        <p>Administrative Block to facilitate administration and management of the institution</p>
                                    </div>
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> Construction of a compound wall surrounding the Kalasala premises</li>
                                        <li><i className="flaticon-check"></i> Repairs and / or renovation of existing buildings</li>
                                        <li><i className="flaticon-check"></i> Provide for basic amenities to student community</li>
                                        <li><i className="flaticon-check"></i> Provide the facilities required for teaching and non-teaching staff of the institution</li>
                                    </ul>
                                </div>
                                <br/>
                                <ContactForm />
                                <p>In order to complete the above mentioned works, we will incur a lot of expenditure. Your financial patronage will go a long way in forwarding our cause and your generosity will be aptly recognized by our institution. Further, the donations made to the Andhra Jatheeya Vidya Parishad are eligible for Income Tax Exemption under section 80G(5) as per the order of Income Tax Commissioner, Vijayawada bearing number 80G/AACAA9117E/2013-14/26 dated 30th January 2014.</p>
                                <p>Thank you for considering our request. If you have any questions or need further information, please feel free to contact us.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="side-bar-area">
                                <div className="side-bar-widget">
                                    <h3 className="title">Quick Links</h3>
                                    <div className="side-bar-categories">
                                    <ul>
                                        <li><Link to="/founder">Founder info</Link></li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/chairman">Chairman</Link></li>
                                        <li><Link to="/visitors">Visitors</Link></li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="side-bar-widget">
                                    <h3 className="title">About</h3>
                                    <div className="widget-popular-post">
                                        <article className="item">
                                            <Link to="/chairman" className="thumb">
                                                <span className="full-image cover bg31" role="img" style={{backgroundImage:`URL('/images/team/founder.jpg')`}}></span>
                                            </Link>
                                            <div className="info">
                                                <p>Sri K. Hanumantha Rao Panthulu Garu</p>
                                                <h4 className="title-text">
                                                    <Link to="/chairman">Founder & First Principal</Link>
                                                </h4>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <Link to="/chairman" className="thumb">
                                                <span className="full-image cover bg31" role="img" style={{backgroundImage:`URL('/images/team/chairman.jpg')`}}></span>
                                            </Link>
                                            <div className="info">
                                                <p>Dr. Kopalle Hanumantha Rao</p>
                                                <h4 className="title-text">
                                                    <Link to="/chairman">Chairman and Founder Family Member</Link>
                                                </h4>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="side-bar-widget">
                                    <h3 className="title">Academics</h3>
                                    <ul className="side-bar-widget-tag">
                                        <li><Link to="/academics/ajkalasala">AJ Kalasala</Link></li>
                                        <li><Link to="/academics/ajkiti">A.J.K I.T.I</Link></li>
                                        <li><Link to="/academics/ajkorientalsecondaryschool">A.J.K Oriental Secondary School</Link></li>
                                        <li><Link to="/academics/ajcollegeofeducation">AJ College of Education</Link></li>
                                        <li><Link to="/academics/ajkdiplomainseedtechnology" >A.J.K Diploma in Seed Technology</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default AluminiScreen;