import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function AcademicsSection1(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
    <div className="featured-area pt-100 pb-70">
        <div className="container">
            <div className="row align-items-center mb-45">
                <div className="col-lg-8 col-md-9">
                    <div className="section-title mt-rs-20">
                        <h2>Academics</h2>
                        <p><strong>Andhra Jatheeya Kalasala</strong> was established in the year 1910 under the leadership of our great dynamic leader Sri Kopalle Hanumantha Rao Panthulu garu. Being an educationist himself he understood the nuances of national education and had a great vision in developing the institution in a larger way.</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-3 col-6 col-md-4">
                    <div className="featured-card">
                        <Link to="/academics/ajkalasala">
                            <i className="flaticon-studying"></i>
                            <h3>AJ Kalasala</h3>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 col-6 col-md-4">
                    <div className="featured-card">
                        <Link to="/academics/ajkiti">
                            <i className="flaticon-studying"></i>
                            <h3>A.J.K I.T.I</h3>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 col-6 col-md-4">
                    <div className="featured-card">
                        <Link to="/academics/ajkorientalsecondaryschool">
                            <i className="flaticon-studying"></i>
                            <h3>A.J.K Oriental Secondary School</h3>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 col-6 col-md-4">
                    <div className="featured-card">
                        <Link to="/academics/ajcollegeofeducation">
                            <i className="flaticon-studying"></i>
                            <h3>AJ College of Education</h3>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 col-6 col-md-4">
                    <div className="featured-card">
                        <Link to="/academics/ajkdiplomainseedtechnology">
                            <i className="flaticon-studying"></i>
                            <h3>A.J.K Diploma in Seed Technology</h3>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default AcademicsSection1;
