import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function WhoWeAreSection1() {
	const {skin} = useContext(GlobalContext);
	return (
        <div className="enrolled-area-two pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="enrolled-img-three mb-30 pr-20">
                            <img src={"/images/buildings/AJ-Kalasala2.jpg"} alt="Enrolled" />
                            <div className="enrolled-img-content">
                                <i className="flaticon-student"></i>
                                <div className="content">
                                    <h3>Estd.In</h3>
                                    <p>1907</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="enrolled-content mb-30">
                            <div className="section-title">
                                <span>WHO WE ARE</span>
                                <h2>A J Vidya Parishad has a history of 115+ years.</h2>
                                <p>Sri Kopalle Hanumantha Rao Panthulu Garu was a renowned educationist and stalwart freedom fighter in the time of Pre-Independent India. He actively participated in the Freedom movement by closely associating with Sri Lala Lajapathi Roy, Sri Bala Gangadhar Tilak and Sri Bipin Chandra Pal (Lal-Bal-Pal). He also led the Indian National Congress in Andhra Pradesh before Gandhian era. The conglomeration of his ideologies towards Swaraj and in response to the nation’s call to make India self-sufficient,he put forward a constructive work by bequeathing his family property for the establishment of “Andhra Jatheeya Kalasala” and the creation of a body that is to be known as Andhra Jatheeya Vidya Parishad, on 17th November, 1907. The Parishad itself was registered under the provisions of Act XXI of 1860 of the Acts of the Viceroy and Governor-General of India in Council, being an Act for the registration of Library, Scientific and Charitable Societies, on 19-6-1911.</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-6">
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> Endowments Department</li>
                                        <li><i className="flaticon-check"></i> 115+ Years History</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <ul className="enrolled-list">
                                        <li><i className="flaticon-check"></i> Strong Management</li>
                                        <li><i className="flaticon-check"></i> Best Faculty</li>
                                    </ul>
                                </div>
                            </div>
                            <Link to="/contact" className="default-btn border-radius-50">Visit us today</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}
export default WhoWeAreSection1;

