import React, { Suspense, useState, useEffect, useRef } from "react";
import { BrowserRouter,Routes, Route,} from "react-router-dom";
import { GlobalProvider } from './GlobalContext';

function App(props) {
  const {skin} = props;
  const Endpoint = window.location.host.split(":")[0]==="localhost"?"http://localhost:5000/ajvidyaparishad/us-central1/api/api":"/api";
  const Root = require('./skins/'+skin+'/navigator/Root').default;
  return (
    <div className="App">
      <GlobalProvider value={{skin,Endpoint,}}><Root /></GlobalProvider>
    </div>
  );
}

export default App;
