import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import StatsSection1 from "../components/StatsSection1";
import AcademicsSection1 from "../components/AcademicsSection1";
import EminentPersonalities from "../components/EminentPersonalities";
import OurInstructors from "../components/OurInstructors";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

const axios = require('axios').default;
function SubscribeToNewletters1() {
	const {skin,Endpoint} = useContext(GlobalContext);
    const [IsSubscribing,setIsSubscribing] = useState(false);
	const [NewsletterEmail,setNewsletterEmail] = useState("");
	const [SuccessMessage,setSuccessMessage] = useState("");
	const [ErrorMessage,setErrorMessage] = useState("");
	const SubscribeToNewsletters = async ()=>{
		if(IsSubscribing == true) return;
		setSuccessMessage("");
		setErrorMessage("");
		if(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(NewsletterEmail) === "") return setErrorMessage("Invalid Email.");
		setIsSubscribing(true);
		axios.post(Endpoint+'/newsletter/subscribe',{email:NewsletterEmail}).then(function (response) {
			if(response.data.errorcode !== 0) return setErrorMessage(response.data.errortext);
			setSuccessMessage(response.data.errortext);
		}).catch(function (error) {
			console.log(error);
			setErrorMessage(error.message);
		}).then(function () {setIsSubscribing(false);});
	}
	return (
		<div className="newsletter-area section-bg ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title mt-rs-20">
                            <span>WANT TO GET LATEST NEWS AND UPDATE?</span>
                            <h2>Subscribe our newsletter</h2>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <form className="newsletter-form" data-toggle="validator" method="POST" onSubmit={(event)=>{SubscribeToNewsletters();event.preventDefault();return false;}}>
                            <input type="email" className="form-control" placeholder="Enter Your Email Address" name="EMAIL" required autoComplete="off" value={NewsletterEmail} onChange={(e)=>{setNewsletterEmail(e.target.value);}} />
                            {
                                IsSubscribing != true ? 
                                <button className="subscribe-btn" type="submit">Subscribe Now <i className="flaticon-paper-plane"></i></button>    
                                :
                                <button className="subscribe-btn" type="button">Subscribing... <i className="flaticon-paper-plane"></i></button>
                            }
                            {SuccessMessage != "" && <div id="validator-newsletter" className="form-result" style={{color:"green",fontSize:12,paddingLeft:25,}}>{SuccessMessage}</div> }
                            {ErrorMessage != "" && <div id="validator-newsletter" className="form-result" style={{color:"red",fontSize:12,paddingLeft:25,}}>{ErrorMessage}</div> }
                        </form>
                    </div>
                </div>
            </div>
        </div>
	);
}
export default SubscribeToNewletters1;

