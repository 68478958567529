import React, {useContext, useState, useEffect, } from 'react';
import { Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import Gallery from "../components/Gallery";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function GalleryScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Welcome</title></Helmet>
			<ScrollToTop />
			<Header bgcolor={"gray"} />
			<HeroSection4 header={"Gallery"} description={"Image Gallery for Eminent Personalities, Team, Event and more."}/>
			<Gallery />
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default GalleryScreen;

