import React, {useContext, useState, useEffect, } from 'react';
import { Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import VisitorsSection1 from "../components/VisitorsSection1";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function VisitorsScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Visitors</title></Helmet>
			<ScrollToTop />
			<Header />
			<VisitorsSection1 />
			<BecomeSupportPartnerSection1 />
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default VisitorsScreen;