import React, {useContext, useState, useEffect, } from 'react';
import { Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import ContactDetailsSection1 from "../components/ContactDetailsSection1";
import ContactForm from "../components/ContactForm";
import ContactMap from "../components/ContactMap";
import SubscribeToNewletters1 from "../components/SubscribeToNewletters1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';

function ContactusScreen() {
	const {skin} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Contact Us</title></Helmet>
			<ScrollToTop />
			<Header />
			<HeroSection4 header={"Contact Us"} description={"We will be more than happy to hear from you."}/>
            <ContactDetailsSection1 />
            <ContactForm />
            <ContactMap />
			<SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default ContactusScreen;