import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function OurInstructors(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <div className="instructors-area pb-70">
            <div className="container">
                <div className="row align-items-center mb-45">
                    <div className="col-lg-8 col-md-9">
                        <div className="section-title mt-rs-20">
                            <h2>Meet our Team</h2>
                            <p>Want to know more about out team, here are our Top Team Members</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-3 text-end">
                        <Link to="/contact" className="default-btn">Contact Us</Link>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6">
                        <div className="instructors-item">
                            <div className="instructors-img">
                                <Link to="/chairman">
                                    <img src={"/images/team/Dr.KopalleHanumanthaRao.png"} alt="Team Images" />
                                </Link>
                                <ul className="instructors-social">
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i className="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i className="ri-linkedin-box-line"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="content">
                                <h3><Link to="/chairman">Dr. Kopalle Hanumantha Rao B.Sc., LL.M., Ph.D</Link></h3>
                                <span>B.Sc., LL.M., Ph.D<br/><b>Chairman and Founder Family Member </b></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="instructors-item">
                            <div className="instructors-img">
                                <Link to="/founder">
                                    <img src={"/images/team/founder1.png"} alt="Team Images" />
                                </Link>
                                <ul className="instructors-social">
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i className="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i className="ri-linkedin-box-line"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="content">
                                <h3><Link to="/founder">Sri K. HANUMANTHA RAO PANTHULU GARU ,</Link></h3>
                                <span>M.A., B.L.<br/><b>Founder & First Principal</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="instructors-item">
                            <div className="instructors-img">
                                <Link to="/correspondent">
                                    <img src={"/images/team/Dr.PJ.Kudumbarao.png"} alt="Team Images" />
                                </Link>
                                <ul className="instructors-social">
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i className="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i className="ri-linkedin-box-line"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="content">
                                <h3><Link to="/correspondent">Dr PJ Kudumbarao MA, MA, BL, PhD</Link></h3>
                                <span>MA, MA, BL, PhD<br/><b>Correspondent </b></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="instructors-item">
                            <div className="instructors-img">
                                <Link to="/executiveofficer">
                                    <img src={"/images/team/SriG.V.D.N.LeelaKumar.png"} alt="Team Images" />
                                </Link>
                                <ul className="instructors-social">
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i className="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i className="ri-linkedin-box-line"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="content">
                                <h3><Link to="/executiveofficer">Sri G.V.D.N.Leela Kumar,</Link></h3>
                                <span>BCom.<br/><b>Assistant Commissioner & Executive Officer</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default OurInstructors;
