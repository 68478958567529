import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Aos from 'aos';


import { GlobalContext } from '../../../GlobalContext';


function StatsSection1(props) {
    const theme = props.theme == undefined?"":props.theme;
	const {skin} = useContext(GlobalContext);
    useEffect(()=>{
        Aos.init({easing: 'ease-in-out',once: true,duration: 500});
    },[]);
    return (
        <div className="counter-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-6 col-md-3">
                        <div className="counter-content">
                            <i className="flaticon-online-course"></i>
                            <h3><span className="odometer" data-count="15000">100</span>+</h3>
                            <p>Years Experience</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 col-md-3">
                        <div className="counter-content">
                            <i className="flaticon-student"></i>
                            <h3><span className="odometer" data-count="145000">1250</span>+</h3>
                            <p>Students enrolled</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 col-md-3">
                        <div className="counter-content">
                            <i className="flaticon-online-course-1"></i>
                            <h3><span className="odometer" data-count="10000">25</span>+</h3>
                            <p>instructors</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 col-md-3">
                        <div className="counter-content">
                            <i className="flaticon-customer-satisfaction"></i>
                            <h3><span className="odometer" data-count="100">100</span>%</h3>
                            <p>Satisfaction rate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StatsSection1;
