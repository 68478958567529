import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Menu from './Menu'; 

import { GlobalContext } from '../../../GlobalContext';


function Header(props) {
    const theme = props.theme == undefined?"":props.theme;
    const bgcolor = props.bgcolor == undefined?"":props.bgcolor;
	const {skin} = useContext(GlobalContext);
    const [MenuVisibility,setMenuVisibility] = useState(false);
    return (
        <div className={"navbar-area "+(bgcolor == "gray" ? "ledu-area":"")}>
            <div className="mobile-responsive-nav">
                <div className="container">
                    <div className="mobile-responsive-menu mean-container">
                        <div className="mean-bar">
                            <a href="#nav" onClick={(e)=>{setMenuVisibility(!MenuVisibility);e.preventDefault();}} className="meanmenu-reveal" style={{top:20,right: 0,left: "auto",textAlign: "center", textIndent: 0, fontSize: 18}}>
                                {MenuVisibility==false?<><span><span><span></span></span></span></>:<>X</>}
                            </a>
                            <nav className="mean-nav" style={{marginTop:100}}>
                                <Menu display={MenuVisibility==false?"none":""} formobile={true} />
                                <div className="others-options d-flex align-items-center">
                                    <div className="optional-item"><Link to="/" className="default-btn two border-radius-50">Sign Up</Link></div>
                                </div>
                            </nav>
                        </div>
                        <div className="logo" style={{width:"70%"}}>
                            <Link to="/">
                                <img src={"/skins/"+skin+"/assets/images/logos/logo-small.png"} className="logo-one" alt="logo" style={{height:90}} />
                                <img src={"/skins/"+skin+"/assets/images/logos/logo-small-white.png"} className="logo-two" alt="logo" style={{height:90}} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop-nav nav-area">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-md navbar-light ">
                        <Link className="navbar-brand" to="/">
                            <img src={"/skins/"+skin+"/assets/images/logos/logo.png"} className="logo-one" alt="Logo" style={{height:110}} />
                            <img src={"/skins/"+skin+"/assets/images/logos/logo-2.png"} className="logo-two" alt="Logo" style={{height:110}} />
                        </Link>
                        <div className="navbar-category">
                            <div className="dropdown category-list-dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtoncategory" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className='flaticon-list'></i>
                                    Academics
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButtoncategory">
                                    <Link to="/academics/ajkalasala" className="nav-link-item"><i className="flaticon-studying"></i>AJ Kalasala</Link>
                                    <Link to="/academics/ajkiti"><i className="flaticon-studying"></i>A.J.K I.T.I</Link>
                                    <Link to="/academics/ajkorientalsecondaryschool"><i className="flaticon-studying"></i>A.J.K Oriental Secondary School</Link>
                                    <Link to="/academics/ajcollegeofeducation"><i className="flaticon-studying"></i>AJ College of Education</Link>
                                    <Link to="/academics/ajkdiplomainseedtechnology"><i className="flaticon-studying"></i>A.J.K Agricultural</Link>
                                </div>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <Menu />
                            <div className="others-options d-flex align-items-center">
                                <div className="optional-item"><Link to="/become-supporter" className="default-btn two border-radius-50">Become Supporter</Link></div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}
export default Header;
