import React, {useContext, useState, useEffect, } from 'react';
import { Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import { GlobalContext } from '../../../GlobalContext';

function ContactMap() {
	const {skin} = useContext(GlobalContext);
	return (
        <div className="contact-map-area pb-100">
            <div className="container">
                <div className="contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3831.7877284052497!2d81.11521061622965!3d16.17989341023751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a49e7cb31017823%3A0x428d22bf8f5fa498!2sAndhra%20Jateeya%20Kalasala%20(National%20College)!5e0!3m2!1sen!2sin!4v1653321350349!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
	);
}
export default ContactMap;
