import React, {useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../../../GlobalContext';
function Menu(props) {
    const {display,formobile} = props;
    return (
        <ul className="navbar-nav" style={{display}}>
            <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
            {
                formobile == true ?
                <>
                    <li className="nav-item"><Link to="/aboutus" className="nav-link">About Us</Link></li>
                    <li className="nav-item"><Link to="/founder" className="nav-link">&nbsp; &#8594; Founder Info</Link></li>
                    <li className="nav-item"><Link to="/chairman" className="nav-link">&nbsp; &#8594; Chairman's Desk</Link></li>
                    <li className="nav-item"><Link to="/eminentpersonalities" className="nav-link">&nbsp; &#8594; Eminent Personalities</Link></li>
                    <li className="nav-item"><Link to="/infrastructure" className="nav-link">&nbsp; &#8594; Infrastructure</Link></li>
                </>
                :
                <li className="nav-item">
                    <Link to="/aboutus" className="nav-link dropdown-toggle">About Us</Link>
                    <ul className="dropdown-menu">
                        <li className="nav-item"><Link to="/founder" className="nav-link">Founder Info</Link></li>
                        <li className="nav-item"><Link to="/chairman" className="nav-link">Chairman's Desk</Link></li>
                        <li className="nav-item"><Link to="/eminentpersonalities" className="nav-link">Eminent Personalities</Link></li>
                        <li className="nav-item"><Link to="/infrastructure" className="nav-link">Infrastructure</Link></li>
                    </ul>
                </li>
            }
            {
                formobile == true && 
                <>
                    <li className="nav-item"><a href="javascript:;" className="nav-link">Academics</a></li>
                    <li className="nav-item"><Link to="/academics/ajkalasala" className="nav-link">&nbsp; &#8594; AJ Kalasala</Link></li>
                    <li className="nav-item"><Link to="/academics/ajkiti" className="nav-link">&nbsp; &#8594; A.J.K I.T.I</Link></li>
                    <li className="nav-item"><Link to="/academics/ajkorientalsecondaryschool" className="nav-link">&nbsp; &#8594; A.J.K Oriental Secondary School</Link></li>
                    <li className="nav-item"><Link to="/academics/ajcollegeofeducation" className="nav-link">&nbsp; &#8594; AJ College of Education</Link></li>
                    <li className="nav-item"><Link to="/academics/ajkdiplomainseedtechnology" className="nav-link">&nbsp; &#8594; A.J.K Agricultural </Link></li>
                </>
            }
            {
                formobile == true ?
                <>
                    <li className="nav-item"><Link to="/alumini" className="nav-link">Alumini</Link></li>
                    <li className="nav-item"><Link to="/alumini" className="nav-link">&nbsp; &#8594; Reg as Alumini</Link></li>
                    <li className="nav-item"><Link to="/visitors" className="nav-link">&nbsp; &#8594; Visitors</Link></li>
                </>
                :
                <li className="nav-item">
                <Link to="/alumini" className="nav-link dropdown-toggle">Alumini</Link>
                <ul className="dropdown-menu">
                    <li className="nav-item"><Link to="/alumini" className="nav-link">Reg as Alumini</Link></li>
                    <li className="nav-item"><Link to="/visitors" className="nav-link">Visitors</Link></li>
                </ul>
            </li>
            }
            <li className="nav-item"><Link to="/gallery" className="nav-link">Gallery</Link></li>
            <li className="nav-item"><Link to="/contact" className="nav-link">Contact</Link></li>
            {
                formobile == true && 
                <div className="d-flex align-items-center" style={{marginBottom:20,justifyContent:'center'}}>
                    <div className="optional-item">
                        <Link to="/become-supporter" className="default-btn two border-radius-50">Become Supporter</Link>
                    </div>
                    
                </div>
            }
        </ul>);
}
export default Menu;
