import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from '../components/ScrollToTop';
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import SubscribeToNewletters1 from '../components/SubscribeToNewletters1';
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function FounderScreen() {
	const {skin,Endpoint} = useContext(GlobalContext);
	return (
		<>
			<Helmet><title>Founder - Sri K.HANUMANTHA RAO, M.A., B.L.,</title></Helmet>
            <ScrollToTop />
			<Header />
			<HeroSection4 header={"Founder Info"} description={"Sri K.HANUMANTHA RAO, M.A., B.L.,"} />
			<div className="instructors-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="instructors-details-img">
                                <img src={"/images/team/founder.jpg"} alt="Founder" />
                                <ul className="social-link">
                                    <li className="social-title">Follow me:</li>
                                    <li><a href="https://www.facebook.com/" target="_blank"><i className="ri-facebook-fill"></i></a></li>
                                    <li><a href="https://twitter.com/" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                                    <li><a href="https://www.pinterest.com/" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="instructors-details-content pl-20">
                                <h3>Sri K.Hanumantha Rao, M.A., B.L.,</h3>
                                <span className="sub-title">Founder</span>
                                <ul>
                                    <li>(1879 - 1922)<br/>(Served the Institution between 1907-1922)</li>
                                    <li>Memorial of the Founder Sri Kopalle Hanumantha Rao Panthulu garu</li>
                                    <li>It was presented by an Alumni Sri Gangiredla Krishnamurthy Naidu garu.</li>
                                    <li>Sri Kopalle Hanumantha Rao Garu was the Founder of the prestigious educational institution “ANDHRA JATHEEYA VIDYA PARISHAD, formed on 17-11-1907. Here is a biography of this great personality.</li>
                                    <li><b>Birth of a legend and about his family</b></li>
                                    <li>In the year 1879 on April 12th, in the village of Aviripudi in Divi Taluka – current day Krishna District, Sri Kopalle Krishna Rao Panthulugaru and Smt. Seshamma garu were blessed with their first son - who would become a great legend, stalwart freedom fighter and a visionary educationist. Sri Kopalle Krishna Rao garu was a noted Advocate in the region and later worked as ‘Dewan’ in the estates of Challapalli Zamindar Srimath Yarlagadda Raja Mallikarjuna Prasad Naidu Bahadur.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="courses-area pb-70">
                <div className="container">
                    <div className="section-title mb-45">
                        <h2>Education</h2>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu studied in the Hindu High School Machilipatnam.  He was a brilliant student like his father Sri Krishna Rao garu. He met his best friend Sri Mutnuri Krishna Rao Garu while studying in school.</p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu completed his graduation B.A in the year 1896 and then M.Sc Botany in Madras Presidency College. His was the first person to get Master’s Degree in Divi Taluka.  After his education, he worked in Ooty as Assistant Conservator of Forest for 2 years. He got selected as the Deputy Collector, but he opted to serve people instead, than his Government job.</p>
                        <p>Later he completed B.L from Madras University in 1904 and practiced law in Masulipatnam.  He left that occupation also and joined the Indian Independence movement.</p>
                    </div>
                    <div className="section-title mb-45">
                        <h2>Conceptualization of the Kalasala</h2>
                        <p>The port town of Bandar (Masulipatnam) occupied a central place in the national movements.  In those days of British rule, people used to consider nothing hypocritical than the slavery to the English.  The institutions running under state control were following methods of compulsive teaching and according to their whims. People across the nation revolted against such education and resolved to initiate the concept of national education. As part of the Swaraj Movement, there were many national educational institutions established. However, Andhra Jatheeya Kalasala is the only institution which still exists with the same glory and grandeur.  This is possible today only because of our founder’s commitment and dedication in those days.</p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu, Sri Mutnuri Krishna Rao and Sri Bhogiraju Pattabhi Sitaramayya are famous as the Trio of Masulipatnam before the era of Independent India. Since 1906, their collaborated efforts were seen in taking up any tasks related to the National Movement. Their association with Sri Kowta Srirama Sastry garu and Brahmarshi Raghupathi Venkataratnam Naidu garu had further endured to spread their ideologies.  These are the scholars and wise people who had spent their life in the National Resurrection. With their indomitable spirit of discipline and capability, they have earned accolades from the people all over the country.</p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu’s great qualities of being kind and humane, being humble, having great sense of sacrifice and being benevolent, have made him the foremost among the trio.  He was inspired and motivated by the writings and speeches of the <strong>‘Grand Old Man of India’ Dadabhai Naoroji, Lokamanya Bala Gangadhar Tilak, ‘Punjab Kesari’ Lala Lajpat Rai, and Bipin Chandra Pal</strong>. He was closely associated with Sri Bipin Chandra Pal.  This motivation led to his determination to work along the path of National Education Movement.  In the year 1907 Sri Kopalle Hanumantha Rao Panthulu established the Andhra Jatheeya Vidya Parishad in the same lines of the establishment of ‘Vanga Jatheeya Vidya Parishad’ in Calcutta.</p>
                        <p>Many freedom fighters formed part of this movement by supporting the Parishad.  Sri Kopalle Hanumantha Rao Panthulu garu was a foremost leader who contributed all his might to this great cause of national educational movement. His quest and zeal towards this cause was quite unique. Along with his commitment, Sri Mutnuri Krishna Rao garu and Sri B. Pattabhi Seetharamaiah garu supported him in this noble cause.</p>
                    </div>
                </div>
            </div>
            <div className="enrolled-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="enrolled-content">
                                <div className="section-title">
                                    <h2>Institution under the aegis of a great leader</h2>
                                    <p>Sri Kopalle Hanumantha Rao Panthulu garu established the Andhra Jatheeya Kalasala on 20th Feb 1910 which was inaugurated by ‘Desabhakta’ Konda Venkatappayya Panthulu garu.</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <ul className="enrolled-list">
                                            <li><i className="flaticon-check"></i> Supporting the National Freedom Movement</li>
                                            <li><i className="flaticon-check"></i> Help towards National Development</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <ul className="enrolled-list">
                                            <li><i className="flaticon-check"></i> Guiding the youth to join the grand cause of Indian Independence</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="enrolled-img" data-speed="0.05" data-revert="true">
                                <img src={"/images/team/founder-memorial.png"} alt="Memorial Of the Founder Sri Kopalle Hanumantha Rao Panthulu Garu" />
                                <div className="bg-shape">
                                    <img src={"/skins/"+skin+"/assets/images/enrolled/enrolled-shape.png"} alt="Shape" />
                                </div>
                                <div className="top-content">
                                    <div className="enrolled-img-content">
                                        <i className="flaticon-student"></i>
                                        <div className="content">
                                            <h3>Education</h3>
                                            <p>M.A., B.L.,</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="left-content">
                                    <div className="enrolled-img-content">
                                        <i className="flaticon-reading-book active"></i>
                                        <div className="content">
                                            <h3>Founder Of</h3>
                                            <p>AJ Kalasala</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-content">
                                    <div className="enrolled-img-content">
                                        <i className="flaticon-corporate"></i>
                                        <div className="content">
                                            <h3>Served</h3>
                                            <p>1907 - 1922</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="enrolled-img-shape">
                                    <div className="shape1">
                                        <img src={"/skins/"+skin+"/assets/images/enrolled/enrolled-shape2.png"} alt="Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="courses-area pb-70">
                <div className="container">
                    <div className="section-title mb-45">
                        <p>They used to teach the history of other nations and their leaders that have undergone the similar freedom struggles.  Sri Kopalle Hanumantha Rao Panthulu garu inspired Sri Pingali Venkaiah garu to write a biography of the Chinese revolutionary leader Sun Yat-sen.  He also motivated him to write a book on the importance of the Indian National Flag. <strong><i>10 years before Mahatma Gandhi became the prominent leader of Indian National Movement, he supported the upliftment of Harijans, eradication of untouchability and complete ban on toddy. He also encourage the underprivileged by enrolling into the Kalasala, provide free education to them along with financial support.</i></strong></p>
                        <p>In the Kalasala, he gave more importance to Mechanical Engineering as it will help the national development and the setting up of large scale industries.  He also supported the weaving and handloom as small scale industries.  This has helped many students of the Kalasala to earn their living.  Sri Kopalle Hanumantha Rao Panthulu garu always used to practice what he preached.</p>
                        <p>Agriculture was also one of the main subjects of the Kalasala.  Motors and Pump sets that were made in the Kalasala were supplied and installed in the various villages to help farmers convert the dry and barren lands into agricultural fields. </p>
                        <p>To serve the purpose and fulfil the goals of the Kalasala, he got great educationists and teachers to work for the Kalasala. The great personalities like ‘Andhra Ratna’ Duggirala Gopala Krishnayya garu, Chillarige Srinivasa Rao garu, Kona Seetarama Rao garu, Kolavennu Rama Koteswara Rao garu, ‘Kavi Samrat’ Viswanatha Satyanarayana garu, Eeranki Venkata Sastry garu have worked in the Kalasala as eminent faculty.</p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu himself was teaching history and the importance of National Movement to the students of the Kalasala, even as he was working as the Principal and was touring various places to collect the funds required for the institution.  </p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu always visualized the Kalasala as a national institution instead of limiting it to a specific region or state. In this direction, he inspired Pandit Hrishikesh Sharma to work as Hindi faculty, Palani Velupillai as Mathematics faculty and Pingali Krishna Rao as Arts faculty of the Kalasala. Also he made ‘Mahashilpi’ Pramod Kumar Chatterjee as the head of the Arts Department.</p>
                        <p>To ensure that the Kalasala Annual Day functions were celebrated with great pomp and grandeur, he used to invite eminent personalities like Mahatma Gandhi, Anne Besent, Sarojini Devi, Dr. Mutyala Govindarajulu Naidu, Sir Sankaran Nair, Sir T Sadasiva Iyer, Dr Arandale, Sir C P Ramaswamy Iyer, Sri Alladi Krishna Swamy Iyer, Sir Siva Swamy Iyer, Dr. Rajagopala Chari, Sri Satyamurthy, and Sri Bayya Narasimheswara Sarma, to preside over the celebrations and motivate students of the Kalasala.</p>
                        <p>He has indeed maintained the Kalasala as a true national institution.  Further, great leaders and ministers like Bezawada Gopala Reddy, Newspaper Editors like Shambhu Prasad and Kolluri Koteswara Rao, Engineers like Gangiredla Krishna Murthy, Yagna Narayana Sarma and Satyanarayana, Administrators like Maddi Sudarsanam and Prabhakarji (favourite administrator of Gandhiji), Poets and Authors like Pingali Nagendra Rao, Ayurveda Doctors like Vijaya Saradhi Acharya and great Artists like T V S Sarma, Madhavapeddi Gokhle, Gurram Mallaiah, Thota Venkateswara Rao, Vempati Satyam and Kowta Brothers were all great students of this glorious and historic institution.</p>
                    </div>
                    <div className="section-title mb-45">
                        <h2>The last years of a pioneer</h2>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu was a great patriot, nationalist and philanthropist.  He sacrificed his life for a great cause of encouraging the national education.</p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu’s dedication and commitment towards the Andhra Jatheeya Kalasala is indomitable.  The British had termed the institution as rebellious and had stopped the grants to the Kalasala.  In the time of such crisis, Anne Besent had extended her help by donating Rs. 30000, also Mahatma Gandhi asked the Indian National Congress to donate Rs. 27000 in the year 1921.</p>
                        <p>Sri Kopalle Hanumantha Rao Panthulu garu’s determination to see the Kalasala to grow and prosper, in the years to come, had affected his health gradually, which he ignored.  This resulted in his sudden demise due to ill-health at a young age of 42, just after about 12 years of the establishment of the Kalasala.  Though his early demise was a loss to the Kalasala, his ideals did not fade out and made him immortal.  He was the founder of this institution and also conceptualised and foresaw a bright future for it. There are many leaders who supported and contributed to this cause.</p>
                        <p>The Kalasala was later managed by Pattabhi Seetaramaiah and Mutnuri Krishna Rao with the help of Sedimbi, Kowta, Chillarige, Kalatapasvi Chatterjee, Kolavennu, Adivi and others.  However, they could not ensure to provide the required support and strength to the institution. </p>
                        <p>In our Indian history, we have come across many eminent personalities who were born with a genuine cause to serve our nation. Though they appear as common men, they have distinct personality and qualities that differentiate them from others. Sri Kopalle Hanumantha Rao Panthulu garu was one such great personality. He sacrificed his entire life to the cause of Indian Independence and propagating the cause of national education. He would always be remembered as the pioneer in the arena of National Education and Indian freedom struggle...</p>
                    </div>
                </div>
            </div>
            <SubscribeToNewletters1 />
			<Footer />
		</>
	);
}
export default FounderScreen;
