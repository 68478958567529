import React, {useContext, useState, useEffect, } from 'react';
import { useParams, Link, Routes, Route,} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import ScrollToTop from "../components/ScrollToTop";
import Header from "../components/Header";
import HeroSection4 from "../components/HeroSection4";
import BecomeSupportPartnerSection1 from "../components/BecomeSupportPartnerSection1";
import Footer from '../components/Footer';

import { GlobalContext } from '../../../GlobalContext';
const axios = require('axios').default;
function VerifyNewsletterSubscriptionScreen() {
	const {skin,Endpoint} = useContext(GlobalContext);
    const {verifykey} = useParams();
    const [InProcess,setInProcess] = useState(false);
    const [Email,setEmail] = useState("");
    const [SuccessMessage,setSuccessMessage] = useState("");
    const [ErrorMessage,setErrorMessage] = useState("");
    const VerifyEmailSubscription = async ()=>{
        if(InProcess == true) return;
        setSuccessMessage("");
        setErrorMessage("");
        setInProcess(true);
        console.log("Endpoint:",Endpoint);
        axios.get(Endpoint + '/newsletter/verifyemail/' + verifykey).then(function (response) {
            if(response.data.errorcode !== 0) return setErrorMessage(response.data.errortext);
            setSuccessMessage(response.data.errortext);
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.message);
        }).then(function () {setInProcess(false);});
    }
    useEffect(()=>{
        VerifyEmailSubscription();
    },[]);
	return (
		<>
			<Helmet><title>Newsletter Subscription Confirmation!</title></Helmet>
            <ScrollToTop />
			<Header />
			<HeroSection4 header={"Email Confirmation!"} description={"Confirm your email to Opt in for our Newsletters."} />
			<div className="user-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6">
                            <div className="user-all-form">
                                <div className="contact-form">
                                    <h3 className="user-title"> Email Confirmation </h3>
                                    <form id="contactForm">
                                        <div className="row">
                                            <div className="col-lg-12 ">
                                                <div className="form-group">
                                                    {InProcess === true && <p className="text-muted">Please Wait...</p>}
                                                    {SuccessMessage !== "" && <p className="text-muted">{SuccessMessage}</p>}
                                                    {ErrorMessage !== "" && <p style={{color:"red"}}>{ErrorMessage}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <Link to="/" className="default-btn ">Home</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<BecomeSupportPartnerSection1 />
			<Footer />
		</>
	);
}
export default VerifyNewsletterSubscriptionScreen;
